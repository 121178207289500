import { apiAxios, baseApiAxios } from './api';
import swal from 'sweetalert';
import {
  addCopyButton,
  addLoadAccountDetailsButton,
  addLoadOrderDetailsButton,
  addLoadVPCDetailsButton,
  checkValueAgainstList,
  showErrorFromApiOperation,
  configureTableColumns,
  rowDetailsClickHandler,
  addLinkFourWheelsIdButton,
  hasAccess,
  displayErrorPopup,
  displayInfoPopup,
  validateAcknowledgeDeniedRegion,
  scrollToPosition,
} from './main';
import { addButtonSpinner, addSpinner, addTableSpinner, removeSpinners } from './sidebar';
import { accountIdPattern } from './accounts';
import { centralLoadVPCs, vpcPattern } from './networks';
import { loadHostedZones } from './zones';
import { addDataAndFormatFooter } from './databases';
import ClusterTabs from '../../jsx/components/forms/ClusterTabs';
import { initDataTable } from './datatable';
import { Modal } from 'bootstrap';

// Note: process.env.PORTAL_VERSION gets replaced at build time with the actual version from package.json
const VERSION = process.env.PORTAL_VERSION;

const fourWheelsTableColumns = [
  { id: 'select_col' },
  { id: 'order_id_col', name: 'Order Id', attribute_name: 'order_id' },
  { id: 'project_name_col', name: 'Project Name', attribute_name: 'project_name' },
  { id: 'cluster_name_col', name: 'Cluster Name', attribute_name: 'details.cluster_name' },
  { id: 'aws_account_id_col', name: 'Account ID', attribute_name: 'account_id' },
  { id: 'region_col', name: 'Region', attribute_name: 'region' },
  { id: 'cluster_size_col', name: 'Cluster Size', attribute_name: 'cluster_size' },
  { id: 'cpu_architecture_col', name: 'CPU Architecture', attribute_name: 'cpu_architecture' },
  { id: '4wg_version_col', name: '4Wheels Guided Version', attribute_name: '4wg_version' },
  { id: 'kubernetes_version_col', name: 'Kubernetes Version', attribute_name: 'details.k8s_version' },
  { id: 'logging_stack_col', name: 'Logging Stack', attribute_name: 'logging_stack' },
  { id: 'vpc_id_col', name: 'VPC ID', attribute_name: 'vpc_id' },
  { id: 'zone_name_col', name: 'DNS Zone', attribute_name: 'zone_name' },
  { id: 'status_col', name: 'Cluster Status', attribute_name: 'status' },
  { id: 'status_update_col', name: 'Last Status Update (UTC)', attribute_name: 'status_update' },
  { id: 'account_dl_col', name: 'Account Distribution List', attribute_name: 'account_dl' },
  { id: 'order_proposer_col', name: 'Order Proposer', attribute_name: 'order_proposer' },
  { id: 'actions_col', name: 'Actions' },
];
export { fourWheelsTableColumns };

const fourWheelsDeleteStatus = ['CREATED', 'active', undefined];
const fourWheelsDropdownColumns = [
  'region',
  'cluster_size',
  'logging_stack',
  'status',
  'details.k8s_version',
  '4wg_version',
  'cpu_architecture',
];
const fourWheelsSearchColumns = [
  'order_id',
  'project_name',
  'details.cluster_name',
  'account_id',
  'vpc_id',
  'zone_name',
  'order_proposer',
];

const fourWheelsAttributes = [
  'order_id',
  'project_name',
  'details',
  'account_id',
  'region',
  'cluster_size',
  'logging_stack',
  'vpc_id',
  'zone_name',
  'status',
  'status_update',
  'account_dl',
  'order_proposer',
  '4wg_version',
  'cpu_architecture',
];

const fourWheelsNoDeleteStatus = ['PREPARATION', 'INCREATION', 'INDELETION'];

let fourwheelsTableId;
let current4wheelsTab = 0;
let dashboardShowAllClusters = false;
export function loadFourWheelsData(tableId, headers, forceReload) {
  $(() => addSpinner());
  $(() => addTableSpinner());

  if (forceReload) {
    headers = { ...headers, 'Cache-Control': 'max-age=0, must-revalidate' };
  }

  const params = { attribute_names: fourWheelsAttributes.join(), list_all: dashboardShowAllClusters };

  apiAxios
    .get('/fourwheels', { headers, params })
    .then(response => {
      addDataAndFormatFooter({
        tableId: tableId,
        dataArray: response.data.cluster_items,
        tableColumns: fourWheelsTableColumns,
        dropdownColumns: fourWheelsDropdownColumns,
        searchColumns: fourWheelsSearchColumns,
      });

      updateFourwheelsCounter(response.data.cluster_items);
    })
    .catch(showErrorFromApiOperation('Error fetching 4wheels clusters'))
    .finally(() => {
      removeSpinners();
    });
}

export async function loadFourWheelsClusterOptions(tableId) {
  $(() => addSpinner());
  $(() => addTableSpinner());

  try {
    const { options } = await baseApiAxios.getFourwheelsClusterOptions('all');
    const table = $('#' + tableId).DataTable();
    table.clear();
    table.rows.add(options);
    table.columns.adjust().draw();

    $(window).trigger('resize');
    scrollToPosition();

    if (
      options.length == 0 ||
      !options.find(o => o.option_type === 'cpu_architecture') ||
      !options.find(o => ['fourwheels_version', '4wg_version'].includes(o.option_type))
    ) {
      displayInfoPopup(
        'Please make sure that there is at least 1 option per each available option type, these values are used for the 4Wheels cluster order form dropdown. There are currently no options at all or an option type is missing at least 1 entry.',
        'Missing required cluster options',
      );
    }
  } catch (e) {
    displayErrorPopup(e);
  } finally {
    removeSpinners();
  }
}

export function initFourWheelsTable(tableId) {
  fourwheelsTableId = tableId;
  configureTableColumns(tableId, fourWheelsTableColumns);

  $('#show-all-four-wheels-switch')
    .attr('checked', dashboardShowAllClusters)
    .on('change', function () {
      if (dashboardShowAllClusters !== this.checked) {
        dashboardShowAllClusters = this.checked;
        $('#' + tableId)
          .DataTable()
          .clear();
        loadFourWheelsData(tableId);
      }
    });

  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        extend: 'excelHtml5',
        text: 'Export Excel',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as Excel file',
      },
      {
        extend: 'csvHtml5',
        text: 'Export CSV',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Export the visible columns as CSV file',
      },
      {
        extend: 'copyHtml5',
        text: 'Copy',
        exportOptions: {
          columns: ':visible',
        },
        titleAttr: 'Copy the visible columns into your clipboard',
      },
      {
        extend: 'resetTable',
        ajaxReload: false,
        titleAttr: 'Reset all filters in the table footer',
      },
      {
        extend: 'reloadTable',
        text: 'Reload Clusters',
        ajaxReload: false,
        methodReload: loadFourWheelsData,
        titleAttr: 'Reload Clusters (no-cache)',
      },
    ],
    [
      {
        // Column 0
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: false,
        data: null,
        name: 'select_col',
        class: 'details-control',
        width: '20px',
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'order_id',
        name: 'order_id_col',
        title: 'Order Id',
        createdCell: function (td) {
          addCopyButton(td);
          addLoadOrderDetailsButton(td);
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'project_name',
        name: 'project_name_col',
        title: 'Project Name',
        createdCell: function (td) {
          addCopyButton(td);
          addLinkFourWheelsIdButton(td);
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'details.cluster_name',
        name: 'cluster_name_col',
        title: 'Cluster Name',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'account_id',
        name: 'aws_account_id_col',
        title: 'Account ID',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          addLoadAccountDetailsButton(td);
          if (!accountIdPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'region',
        name: 'region_col',
        title: 'Region',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          const pattern = /^[a-z]{2}-[a-z]{1,12}-\d$/;
          if (!pattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'cluster_size',
        name: 'cluster_size_col',
        title: 'Cluster Size',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'cpu_architecture',
        name: 'cpu_architecture_col',
        title: 'CPU Architecture',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'details.k8s_version',
        name: 'kubernetes_version_col',
        title: 'Kubernetes Version',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: '4wg_version',
        name: '4wg_version_col',
        title: '4Wheels Guided Version',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'logging_stack',
        name: 'logging_stack_col',
        title: 'Logging Stack',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'vpc_id',
        name: 'vpc_id_col',
        title: 'VPC ID',
        createdCell: function (td, cellData) {
          addCopyButton(td);
          addLoadVPCDetailsButton(td);
          if (!vpcPattern.test(cellData)) {
            $(td).addClass('portal-danger');
          }
        },
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'zone_name',
        name: 'zone_name_col',
        title: 'DNS Zone',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: 'unknown',
        orderable: true,
        searchable: true,
        data: 'status',
        name: 'status_col',
        title: 'Cluster Status',
        createdCell: function (td, cellData) {
          addCopyButton(td);

          if (['active', 'CREATED'].indexOf(cellData) !== -1) {
            $(td).addClass('portal-success');
          } else if (['PrepareDeployment', 'PREPARATION', 'INCREATION', 'INDELETION'].indexOf(cellData) !== -1) {
            $(td).addClass('portal-warning');
          } else if ('FAILED' === cellData) {
            $(td).addClass('portal-danger');
          } else {
            $(td).addClass('portal-neutral');
          }
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'status_update',
        name: 'status_update_col',
        title: 'Last Status Update (UTC)',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'account_dl',
        name: 'account_dl',
        title: 'Account Distribution List',
        createdCell: addCopyButton,
      },
      {
        visible: false,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'order_proposer',
        name: 'order_proposer',
        title: 'Order Proposer',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        width: '50px',
        createdCell: function (td, _cellData, rowData, row, _col) {
          const buttons = $('<div class="table-action-button-group">').appendTo(td);
          const accessibleFourWheels = hasAccess(rowData.account_id, ['manage_4wheels']);
          const isDeletable = rowData.status == 'CREATED';
          if (
            fourWheelsNoDeleteStatus.indexOf(rowData.status) < 0 ||
            checkValueAgainstList(['manage_4wheels'], localStorage.permissions)
          ) {
            // DELETE BUTTON
            buttons.append(
              $(
                '<button class="btn btn-custom btn-xs delete-cluster-button" data-bs-toggle="tooltip" title="Delete Project"' +
                  (accessibleFourWheels && isDeletable ? '' : ' disabled ') +
                  '>',
              )
                .on('click', evt => delete4wheelsProject(tableId, row, evt))
                .append('<i class="fas fa-trash-alt">'),
            );
          }
        },
      },
    ],
    function (row, data) {
      if (data.status === 'AccountDeleted' || data.status === 'ClusterDeleted' || data.status === 'DELETED') {
        $(row).addClass('row-deleted');
      } else if (data.status === 'Cluster.NotFound') {
        $(row).addClass('row-inactive');
      }
    },
    {
      order: [[1, 'asc']],
    },
  );

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: formatFourWheelsRow });
  loadFourWheelsData(tableId);
}

export function initFourWheelsClusterOptionsTable(tableId) {
  const fourWheelsClusterOptionsTableColumns = [
    { id: 'id_col', name: 'Option Id', attribute_name: 'id' },
    { id: 'option_type_col', name: 'Option Type', attribute_name: 'option_type' },
    { id: 'display_name_col', name: 'Display Name', attribute_name: 'display_name' },
    { id: 'value_col', name: 'Value', attribute_name: 'value' },
    { id: 'actions_col', name: 'Actions' },
    { id: 'is_default_col', name: 'Is Default', attribute_name: 'is_default' },
  ];
  //configureTableColumns(tableId, fourWheelsClusterOptionsTableColumns);
  initDataTable(
    tableId,
    'lCfrtpBi',
    [
      {
        extend: 'reloadTable',
        text: 'Reload List',
        ajaxReload: false,
        methodReload: loadFourWheelsClusterOptions,
        titleAttr: 'Reload List',
      },
      {
        text: 'Create New Option',
        action: function () {
          create4wheelsClusterOption(tableId);
        },
        titleAttr: 'Create New Option',
      },
    ],
    [
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'id',
        name: 'id_col',
        title: 'Option Id',
        createdCell: function (td) {
          addCopyButton(td);
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'option_type',
        name: 'option_type_col',
        title: 'Option Type',
        createdCell: function (td) {
          addCopyButton(td);
        },
      },
      {
        visible: true,
        defaultContent: '-',
        orderable: true,
        searchable: true,
        data: 'display_name',
        name: 'display_name_col',
        title: 'Display Name',
        createdCell: addCopyButton,
      },
      {
        visible: true,
        defaultContent: '',
        orderable: true,
        searchable: true,
        data: 'value',
        name: 'value_col',
        title: 'Value',
        createdCell: function (td) {
          addCopyButton(td);
        },
      },
      {
        visible: true,
        defaultContent: false,
        orderable: true,
        searchable: true,
        data: 'is_default',
        name: 'is_default_col',
        title: 'Is Default',
        createdCell: function (td) {
          addCopyButton(td);
        },
      },
      {
        visible: true,
        defaultContent: '',
        orderable: false,
        searchable: true,
        data: null,
        name: 'actions_col',
        title: 'Actions',
        width: '50px',
        createdCell: function (td, _cellData, rowData, row, _col) {
          const buttons = $('<div class="table-action-button-group">').appendTo(td);
          // DELETE BUTTON
          buttons.append(
            $(
              '<button class="btn btn-custom btn-xs delete-cluster-option" data-bs-toggle="tooltip" title="Delete Cluster Option"/>',
            )
              .on('click', evt => delete4wheelsClusterOption(tableId, rowData, evt))
              .append('<i class="fas fa-trash-alt">'),
          );
          // UPDATE BUTTON
          buttons.append(
            $(
              '<button class="btn btn-custom btn-xs update-cluster-option" data-bs-toggle="tooltip" title="Update Cluster Option"/>',
            )
              .on('click', evt => update4wheelsClusterOption(tableId, rowData, evt))
              .append('<i class="fas fa-pencil-alt icon-color">'),
          );
        },
      },
    ],
    function (row, data) {
      if (data.is_default) {
        $(row).addClass('row-default');
      }
    },
    {
      order: [[1, 'asc']],
    },
  );

  rowDetailsClickHandler({ tableId: tableId, rowDetailCallback: formatFourWheelsRow });
  loadFourWheelsClusterOptions(tableId);
}

function formatFourWheelsRow(row) {
  const data = row.data();
  row.child(<ClusterTabs data={data} />).show();
  row.child()[0].setAttribute('class', 'rowDetails');
  removeSpinners();
}

function updateFourwheelsCounter(data) {
  const interval = 1000 / data.length;

  for (let i = 0; i < data.length; i++) {
    (function (index) {
      setTimeout(function () {
        document.getElementById('fourwheelsProjects').innerText = index + 1;
      }, index * interval);
    })(i);
  }
}

export function show4wheelsTab(n) {
  current4wheelsTab = n;
  let x = document.getElementsByClassName('tab');
  for (let tab of x) {
    tab.style.display = 'none';
  }
  x[n].style.display = 'block';
  let previousTabBtn = document.getElementById('previousTabBtn');
  if (n === 0) {
    previousTabBtn.setAttribute('disabled', true);
  } else if (n + 1 === x.length) {
    previousTabBtn.removeAttribute('disabled');
  } else {
    previousTabBtn.removeAttribute('disabled');
  }
}

export function next4wheelsTab() {
  let x = document.getElementsByClassName('tab');
  if (current4wheelsTab + 1 < x.length) {
    current4wheelsTab += 1;
    if (current4wheelsTab == 1) {
      check4wheelsProjectName();
      return;
    } else if (current4wheelsTab == 2) {
      const regionName = document.getElementById('region')?.value;
      loadHostedZones('hosted-zone-id', 'aws-account-id', regionName);
      addSpinner();
      addButtonSpinner();
      show4wheelsTab(current4wheelsTab);
      return;
    } else {
      show4wheelsTab(current4wheelsTab);
    }
  } else {
    listDnsRecords();
  }
}

function delete4wheelsProject(tableId, row, clickEvent) {
  const target = clickEvent.target;
  const delete_button = target?.classList.contains('delete-cluster-button')
    ? target
    : target?.closest('button.delete-cluster-button');
  let cluster;
  if (typeof row === 'number') {
    const dt = $('#' + tableId).DataTable({ retrieve: true });
    cluster = dt.row(row).data();
  } else if (typeof row === 'string') {
    cluster = JSON.parse(row);
  } else {
    cluster = row;
  }

  if (fourWheelsDeleteStatus.indexOf(cluster.status) < 0) {
    swal({
      title: 'Are you sure?',
      text: "We'll delete the 4wheels project from our database. Are you sure you want to delete the project?",
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
        },
        reset: {
          text: 'Delete',
          value: true,
          className: 'swal-button swal-button--confirm swal-button--danger',
        },
      },
      dangerMode: true,
    }).then(function (response) {
      if (response) {
        cluster['action'] = 'delete-4wheels-project';
        delete_button.disabled = true;
        baseApiAxios.displayResponseAsAlert(baseApiAxios.postClusterData(cluster), () =>
          initFourWheelsTable(fourwheelsTableId),
        );
      }
    });
  } else {
    swal({
      title: 'Are you sure?',
      text: "With this order we'll delete your 4wheels cluster. Are you sure you want to delete it?",
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
        },
        reset: {
          text: 'Create Order',
          value: true,
          className: 'swal-button swal-button--confirm swal-button--danger',
        },
      },
      dangerMode: true,
    }).then(function (response) {
      if (response) {
        const payload = {
          action: 'delete-4wheels-cluster',
          description: 'Delete 4wheels Project ' + cluster.project_name,
          account_id: cluster.account_id,
          project_name: cluster.project_name,
          region: cluster.region,
          portal_version: VERSION,
        };

        baseApiAxios.displayResponseAsAlert(baseApiAxios.createOrder(payload));
        delete_button.disabled = true;
      }
    });
  }
}

async function delete4wheelsClusterOption(tableId, rowData, clickEvent) {
  const target = clickEvent.target;
  const delete_button = target?.classList.contains('delete-cluster-option')
    ? target
    : target?.closest('button.delete-cluster-option');
  let option;
  if (typeof rowData === 'number') {
    const dt = $('#' + tableId).DataTable({ retrieve: true });
    option = dt.row(rowData).data();
  } else if (typeof rowData === 'string') {
    option = JSON.parse(rowData);
  } else {
    option = rowData;
  }

  if (option.is_default) {
    delete_button.disabled = false;
    displayErrorPopup(
      null,
      `Cannot delete the option with the name "${option.display_name}" as it is a default option for ${option.option_type}. Please set another option as the default first before removing this one.`,
    );
  } else {
    try {
      delete_button.disabled = true;
      await baseApiAxios.deleteFourwheelsClusterOption(option.id);
      addTableSpinner();
      // backend config service is set to flush the cache if it's 1 sec old. So we need to wait about 1 sec more.
      setTimeout(() => loadFourWheelsClusterOptions(tableId), 2500);
    } catch (e) {
      delete_button.disabled = false;
      displayErrorPopup(e);
    }
  }
}

export function update4wheelsClusterOption(tableId, rowData, clickEvent) {
  const target = clickEvent.target;
  const update_button = target?.classList.contains('update-cluster-option')
    ? target
    : target?.closest('button.update-cluster-option');

  update_button.disabled = true;

  let current_option;
  if (typeof rowData === 'number') {
    const dt = $('#' + tableId).DataTable({ retrieve: true });
    current_option = dt.row(rowData).data();
  } else if (typeof rowData === 'string') {
    current_option = JSON.parse(rowData);
  } else {
    current_option = rowData;
  }

  const modal = $('#sharedModal');
  const ModalTitle = () => <strong>Update 4Wheels Cluster Option</strong>;
  $('#sharedModalTitle').html(<ModalTitle />);

  const ConfirmButton = () => {
    return (
      <button type="submit" class="btn btn-success" id="submit-updated-4wheels-cluster-option">
        Submit
      </button>
    );
  };

  const Content = () => {
    return (
      <>
        <form id="update-cluster-option-form" name="update-cluster-option-form" class="needs-validation" method="POST">
          <div class="form-group row">
            <label for="option-type" class="col-md-12 col-lg-3 col-xl-2 form-label-text text-align-center">
              <span class="text-truncate">Option Type</span>
            </label>
            <div class="col-md-12 col-lg-9 col-xl-10">
              <select required class="form-control form-select selectpicker" id="option-type" name="option-type">
                <option value="cpu_architecture" selected={current_option.option_type === 'cpu_architecture'}>
                  cpu_architecture
                </option>
                <option
                  value="4wg_version"
                  selected={['fourwheels_version', '4wg_version'].includes(current_option.option_type)}>
                  4wg_version
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="display-name" class="col-md-12 col-lg-3 col-xl-2 form-label-text text-align-center">
              <span class="text-truncate">Display Name</span>
            </label>
            <div class="col-md-12 col-lg-9 col-xl-10">
              <input
                type="text"
                id="display-name"
                name="display-name"
                class="form-control"
                placeholder="The text that will be displayed on the dropdown in the order form."
                value={current_option.display_name}
                title="display-name"
                maxlength="100"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="value" class="col-md-12 col-lg-3 col-xl-2 form-label-text text-align-center">
              <span class="text-truncate">Value</span>
            </label>
            <div class="col-md-12 col-lg-9 col-xl-10">
              <input
                type="text"
                id="value"
                name="value"
                class="form-control"
                placeholder="The value that will be included in the payload of the order."
                title="value"
                value={current_option.value}
                maxlength="100"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="isDefault" class="col-md-12 col-lg-3 col-xl-2 form-label-text text-align-center">
              <span class="text-truncate">Is Default</span>
            </label>
            <div class="col-md-12 col-lg-9 col-xl-10 checkbox-inline">
              <input
                type="checkbox"
                id="isDefault"
                name="isDefault"
                checked={current_option.is_default}
                disabled={current_option.is_default}
              />
              {current_option.is_default && (
                <span style="margin: auto 1rem">
                  {' '}
                  <strong>Note</strong>: to uncheck this, please set another option as a default and this option will
                  automatically be set as non-default.
                </span>
              )}
            </div>
          </div>
        </form>
      </>
    );
  };

  $('#sharedModalFooter').append(<ConfirmButton />);
  $('#sharedModalContent').html(<Content />);
  $('.selectpicker').selectpicker('refresh');

  const validateAndConfirm = async () => {
    let isFormValid = true;
    const inputs = $('#sharedModalContent input, #sharedModalContent select').removeClass('is-invalid');

    for (const input of inputs.toArray()) {
      if (!input.value.trim() && input.required) {
        input.classList.add('is-invalid');
        isFormValid = false;
      }
    }
    if (!isFormValid) return;

    const formData = new FormData(document.getElementById('update-cluster-option-form'));
    addButtonSpinner('submit-updated-4wheels-cluster-option');
    try {
      await baseApiAxios.addOrUpdateFourwheelsClusterOption(
        {
          option_type: formData.get('option-type'),
          display_name: formData.get('display-name'),
          value: formData.get('value'),
          id: current_option.id,
          is_default: Boolean($('#isDefault').is(':checked')),
        },
        'update',
      );
      Modal.getInstance(modal).hide();
      addTableSpinner();
      // backend config service is set to flush the cache if it's 1 sec old. So we need to wait about 1 sec more.
      setTimeout(() => loadFourWheelsClusterOptions(tableId), 2500);
    } catch (e) {
      Modal.getInstance(modal).hide();
      displayErrorPopup(e);
      update_button.disabled = false;
    }
  };
  $('#submit-updated-4wheels-cluster-option').on('click', validateAndConfirm);
  modal.on('hide.bs.modal', () => {
    update_button.disabled = false;
  });
  Modal.getInstance(modal).show();
}

export function create4wheelsClusterOption(tableId) {
  const modal = $('#sharedModal');
  const ModalTitle = () => <strong>Create 4Wheels Cluster Option</strong>;
  $('#sharedModalTitle').html(<ModalTitle />);

  const ConfirmButton = () => {
    return (
      <button type="submit" class="btn btn-success" id="submit-new-4wheels-cluster-option">
        Submit
      </button>
    );
  };

  const Content = () => {
    return (
      <>
        <form
          id="create-new-cluster-option-form"
          name="create-new-cluster-option-form"
          class="needs-validation"
          method="POST">
          <div class="form-group row">
            <label for="option-type" class="col-md-12 col-lg-3 col-xl-2 form-label-text text-align-center">
              <span class="text-truncate">Option Type</span>
            </label>
            <div class="col-md-12 col-lg-9 col-xl-10">
              <select required class="form-control form-select selectpicker" id="option-type" name="option-type">
                <option value="cpu_architecture">cpu_architecture</option>
                <option value="4wg_version">4wg_version</option>
                <option value="" disabled selected>
                  -- Please select an option type --
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="display-name" class="col-md-12 col-lg-3 col-xl-2 form-label-text text-align-center">
              <span class="text-truncate">Display Name</span>
            </label>
            <div class="col-md-12 col-lg-9 col-xl-10">
              <input
                type="text"
                id="display-name"
                name="display-name"
                class="form-control"
                placeholder="The text that will be displayed on the dropdown in the order form."
                title="display-name"
                maxlength="100"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="value" class="col-md-12 col-lg-3 col-xl-2 form-label-text text-align-center">
              <span class="text-truncate">Value</span>
            </label>
            <div class="col-md-12 col-lg-9 col-xl-10">
              <input
                type="text"
                id="value"
                name="value"
                class="form-control"
                placeholder="The value that will be included in the payload of the order."
                title="value"
                maxlength="100"
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="isDefault" class="col-md-12 col-lg-3 col-xl-2 form-label-text text-align-center">
              <span class="text-truncate">Is Default</span>
            </label>
            <div class="col-md-12 col-lg-9 col-xl-10 checkbox-inline">
              <input type="checkbox" id="isDefault" name="isDefault" />
            </div>
          </div>
        </form>
      </>
    );
  };

  $('#sharedModalFooter').append(<ConfirmButton />);
  $('#sharedModalContent').html(<Content />);
  $('.selectpicker').selectpicker('refresh');
  const validateAndConfirm = async () => {
    let isFormValid = true;
    const inputs = $('#sharedModalContent input, #sharedModalContent select').removeClass('is-invalid');

    for (const input of inputs.toArray()) {
      if (!input.value.trim() && input.required) {
        input.classList.add('is-invalid');
        isFormValid = false;
      }
    }

    if (!isFormValid) return;

    const formData = new FormData(document.getElementById('create-new-cluster-option-form'));
    addButtonSpinner('submit-new-4wheels-cluster-option');

    await baseApiAxios.addOrUpdateFourwheelsClusterOption(
      {
        option_type: formData.get('option-type'),
        display_name: formData.get('display-name'),
        value: formData.get('value'),
        is_default: Boolean($('#isDefault').is(':checked')),
      },
      'create',
    );
    removeSpinners();
    Modal.getInstance(modal).hide();
    addTableSpinner();
    // backend config service is set to flush the cache if it's 1 sec old. So we need to wait about 1 sec more.
    setTimeout(() => loadFourWheelsClusterOptions(tableId), 2500);
  };
  $('#submit-new-4wheels-cluster-option').on('click', validateAndConfirm);
  Modal.getInstance(modal).show();
}

function listDnsRecords() {
  let hosted_zone = document.getElementById('hosted-zone-id').value;
  let hosted_zone_id = hosted_zone.split(',')[0].split('/')[2];
  let account_id = document.getElementById('aws-account-id').value.split(';')[0];

  const payload = {
    action: 'list-hostedzone-records',
    account_id: account_id,
    hosted_zone_id: hosted_zone_id,
  };

  addSpinner();
  addButtonSpinner();

  baseApiAxios
    .postDnsData(payload)
    .then(checkDnsResponse)
    .catch(displayErrorPopup)
    .finally(() => removeSpinners());
}

function checkDnsResponse(result) {
  let hosted_zone = document.getElementById('hosted-zone-id').value;
  let fqdn = hosted_zone.split(',')[1];
  let project_name = document.getElementById('project-name').value;
  let project_name_record = project_name + '.' + fqdn;

  let records = result.records;
  let proxy_record_exists = false;
  let project_record_exists = false;

  records.forEach(dns_record => {
    if (dns_record.replace(/\.$/, '') === 'proxy.' + fqdn.replace(/\.$/, '')) {
      proxy_record_exists = true;
    } else if (dns_record.indexOf(project_name + '.' + fqdn) !== -1) {
      project_record_exists = true;
    }
  });

  if (!proxy_record_exists) {
    displayErrorPopup(
      null,
      'Hosted Zone has has no proxy record. Please create a new record proxy.' + fqdn + ' and try again.',
    );
  } else if (project_record_exists) {
    displayErrorPopup(
      null,
      `Hosted Zone has already a record ${project_name_record} Please delete the record or create a new Route 53 Private Hosted Zone.`,
    );
  } else {
    order4wheelsCluster();
  }
}

function check4wheelsProjectName() {
  const payload = {
    action: 'check-4wheels-project-name',
    project_name: document.getElementById('project-name').value,
  };

  baseApiAxios
    .postClusterData(payload)
    .then(() => {
      centralLoadVPCs('region', 'aws-account-id', validate4wheelsCompatibleVpcs, { listId: 'network-id' }, true);
      addSpinner();
      show4wheelsTab(current4wheelsTab);
    })
    .catch(error => {
      current4wheelsTab -= 1;
      displayErrorPopup(
        null,
        `Checking project name failed because of "Error: ${error.response.data.message}" and try again.`,
      );
      removeSpinners();
    });
}

function order4wheelsCluster() {
  let account_id = document.getElementById('aws-account-id').value.split(';')[0];
  let region_name = document.getElementById('region').value;
  let project_name = document.getElementById('project-name').value;
  let cpu_architecture = document.getElementById('cpu-architecture').value;
  let fourwheels_version = document.getElementById('4wheels-version').value;
  let cluster_size = document.getElementById('cluster-size').value;
  let logging_stack = document.getElementById('logging-stack').value;
  let vpc_id = document.getElementById('network-id').value;
  let hosted_zone = document.getElementById('hosted-zone-id').value;
  let hosted_zone_id = hosted_zone.split(',')[0].split('/')[2];
  let fqdn = hosted_zone.split(',')[1];

  swal({
    title: 'Are you sure?',
    text: "We're about to create a new 4wheels project in your AWS account. Are you sure you want to create a new project?",
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      reset: {
        text: 'Create Order',
        value: true,
        className: 'swal-button swal-button--confirm swal-button--danger',
      },
    },
    dangerMode: true,
  }).then(function (response) {
    if (response) {
      addSpinner();
      addButtonSpinner();

      validateAcknowledgeDeniedRegion(
        'acknowledge-denied-region',
        document.getElementById('selected-denied-region').checked,
      )
        .then(() => {
          const payload = {
            action: 'create-4wheels-cluster',
            description: 'Create new 4wheels Project ' + project_name,
            account_id: account_id,
            project_name: project_name,
            cluster_size: cluster_size,
            logging_stack: logging_stack,
            region: region_name,
            vpc_id: vpc_id,
            hosted_zone_id: hosted_zone_id,
            cpu_architecture: cpu_architecture,
            '4wg_version': fourwheels_version,
            fqdn: fqdn,
            portal_version: VERSION,
            acknowledge_denied_region: document.getElementById('acknowledge-denied-region').checked,
          };

          baseApiAxios.displayResponseAsAlert(baseApiAxios.createOrder(payload), () => {
            document.getElementById('aws-account-id').value = null;
            document.getElementById('region').value = null;
            document.getElementById('project-name').value = null;
            document.getElementById('cluster-size').value = null;
            document.getElementById('logging-stack').value = null;
            document.getElementById('network-id').value = null;
            document.getElementById('hosted-zone-id').value = null;
            $('.selectpicker').selectpicker('refresh');
            $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
            current4wheelsTab = 0;
            show4wheelsTab(current4wheelsTab);
          });
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          removeSpinners();
        });
    }
  });
}

export function previous4wheelsTab() {
  if (current4wheelsTab > 0) {
    current4wheelsTab -= 1;
    show4wheelsTab(current4wheelsTab);
  } else {
    console.debug('First tab, nothing to see before this tab');
  }
}

function checkSubnetValidity(subnet, subnetType, availableSubnets, freeIPs) {
  let subText = ' - ' + subnet.length + ' ' + subnetType + ' subnets';
  let availabilityZones = new Array();
  if (subnet.length === availableSubnets) {
    for (let index in subnet) {
      subText += ', ' + subnet[index]['subnet_id'] + ' (' + subnet[index]['ips_free'] + ' IPs free)';

      if (subnet[index]['ips_free'] < freeIPs) {
        subText += ' - Not enough free IPs (min + ' + freeIPs + ' IPs per subnet)';
        return {
          outcome: false,
          text: subText,
        };
      }
      var az = subnet[index]['availability_zone_id'];
      if (availabilityZones.includes(az)) {
        subText += ' - Availability zones must be differents';
        return {
          outcome: false,
          text: subText,
        };
      } else {
        availabilityZones.push(az);
      }
    }
  } else {
    subText += ' - VPC needs to have exactly ' + availableSubnets + ' ' + subnetType + ' subnets';
    return {
      outcome: false,
      text: subText,
    };
  }

  return {
    outcome: true,
    text: subText,
  };
}

function validate4wheelsCompatibleVpcs(result, functionParams) {
  removeSpinners();

  if (result.status === 400) {
    $('#form-error').html('<strong>Warning: </strong>' + result.data.message + ' ');
    $('#form-error').show();
    return;
  }

  const { listId } = functionParams;

  const vpcs = (result && result.vpcs) || [];
  const select = document.getElementById(listId);
  for (let i = select.options.length - 1; i >= 0; i--) {
    select.remove(i);
  }

  jQuery.each(vpcs, function () {
    const opt = document.createElement('option');
    const SUBNET_AVAILABLES = 3;
    const INTRANET_FREE_IPS = 15;
    const PRIVATE_FREE_IPS = 50;
    let mainText = this.vpc_id;
    let subText = this.vpc_name + ' - ' + this.vpc_cidr;
    opt.value = this.vpc_id;
    opt.innerText = mainText;

    let intranetVal = null;
    if (this.subnets && this.subnets.intranet && this.subnets.intranet.length) {
      intranetVal = checkSubnetValidity(this.subnets.intranet, 'intranet', SUBNET_AVAILABLES, INTRANET_FREE_IPS);
    } else {
      subText += '<br> - 0 intranet subnets - VPC needs to have exactly ' + SUBNET_AVAILABLES + ' intranet subnets';
    }

    if (intranetVal) {
      subText += '<br>' + intranetVal.text;
    }
    // if (intranetVal == null || !intranetVal.outcome) {
    //   opt.setAttribute('disabled', true);
    //   opt.setAttribute('data-icon', 'fas fa-times');
    // }
    select.appendChild(opt);

    let privateVal = null;
    if (this.subnets && this.subnets.private && this.subnets.private.length) {
      privateVal = checkSubnetValidity(this.subnets.private, 'private', SUBNET_AVAILABLES, PRIVATE_FREE_IPS);
    } else {
      subText += '<br> - 0 private subnets - VPC needs to have exactly ' + SUBNET_AVAILABLES + ' private subnets';
    }

    if (privateVal) {
      subText += '<br>' + privateVal.text;
    }
    // if (intranetVal && privateVal && !privateVal.outcome) {
    //   opt.setAttribute('disabled', true);
    //   opt.setAttribute('data-icon', 'fas fa-times');
    // }
    select.appendChild(opt);

    opt.setAttribute('data-content', subText);
    opt.setAttribute('cidr', this.vpc_cidr);
    opt.setAttribute('vpc_name', this.vpc_name);
    opt.setAttribute('interfaces', this.interfaces);
    opt.setAttribute('interfaces_customer', this.interfaces_customer);
  });

  $('.selectpicker').selectpicker('refresh');
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
}
